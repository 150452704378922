import * as React from "react";
import SharedHeader from "../components/general/components/SharedHeader";
import DocumentListing from "../components/newsAndVideo/pages/DocumentListing";

// markup
const Profile = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <DocumentListing />
    </React.Fragment>
  );
};

export default Profile;
