import { apiUrl, _axios_base_get_list } from "..";

export default function getEmailByEmailAndCode(
  limit = 1,
  skip = 0,
  query = {},
  accessKey = ""
) {
  return _axios_base_get_list(
    `${apiUrl}/documents/getDocuments`,
    limit,
    skip,
    query,
    accessKey
  );
}
