import { Modal } from "antd";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";

const PAGESIZE = 10;
// markup
const VideosListingPage = (props) => {
  // const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [selectedType, setSelectedType] = useState({});

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);
  useEffect(() => {
    if (!isEmpty(props.data)) {
      setSelectedType(props.data);
    }
  }, [props.data]);

  function close() {
    setVisible(false);
    setSelectedType({});
    if (props.onClose) {
      props.onClose();
    }
  }
  const _renderContent = () => {
    return (
      <div className="lightgreybox-bg-color rounded-3xl px-5 py-12">
        <div>
          <iframe
            src={get(selectedType, "link")}
            width="100%"
            height="610"
            allow="autoplay"
          ></iframe>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <MediaQuery maxWidth={500}>
        <Modal
          wrapClassName="no-padding-modal-body modal-body-background-transparent"
          centered
          width="90%"
          mask
          footer={null}
          className=""
          visible={visible}
          onCancel={() => {
            close();
          }}
        >
          {_renderContent()}
        </Modal>
      </MediaQuery>
      <MediaQuery minWidth={501}>
        <Modal
          wrapClassName="no-padding-modal-body modal-body-background-transparent"
          centered
          width="50%"
          mask
          footer={null}
          className=""
          visible={visible}
          onCancel={() => {
            close();
          }}
        >
          {_renderContent()}
        </Modal>
      </MediaQuery>
    </React.Fragment>
  );
};

export default VideosListingPage;
