import { Collapse, Spin } from "antd";
import { cloneDeep, isEmpty, map } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "../../../locales/useTranslation";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
// import FaqDrawer from "../components/FaqDrawer";
import { comingSoonImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import getDocuments from "../../../newApi/document/getDocuments";
import { mainColor } from "../../../styles/config";
import { languageToNumber } from "../../../utilities/profile";
import ScrollLoadWrapper from "../../general/components/ScrollLoadWrapper ";
import DocumentModal from "../components/DocumentModal";

const { Panel } = Collapse;

const PAGESIZE = 10;
// markup
const VideosListingPage = (props) => {
  // const location = useLocation();
  const { t, languages } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedType, setSelectedType] = useState({});

  useEffect(() => {
    getData((page - 1) * PAGESIZE);
  }, [page]);

  //   useEffect(() => {
  //     getData((1 - 1) * PAGESIZE);
  //   }, [languageType]);

  function getData(skip) {
    setLoading(true);
    // console.log("getData");

    let filteredGroup = {};
    // filteredGroup.languageType = languages === "en" ? 0 : 1;
    filteredGroup.languageType = languageToNumber(languages);

    getDocuments(PAGESIZE, skip, {
      ...filteredGroup,
      sort: {
        sequence: 1,
      },
    })
      .then((res) => {
        // console.log("d res", res);
        let data = get(res, "data");
        if (!isEmpty(data)) {
          setDataSource(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {/* FAQ{" "} */}
                {t("document", sourceKey.newAndVideo)}
                {/* <MediaQuery maxWidth={440}>
                  <br />
                </MediaQuery>
                常问问题 */}
              </div>
            }
            showBack={true}
          >
            <div
              className=""
              // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
            >
              <ScrollLoadWrapper
                style={{ height: "73vh" }}
                onScrolledBottom={() => {
                  let length = cloneDeep(page);
                  setPage(length + 1);
                }}
              >
                {isEmpty(dataSource) && loading === false && (
                  <div className="flex justify-center mt-4">
                    <img src={comingSoonImg} style={{ height: "60vh" }} />
                  </div>
                )}
                {map(dataSource, (item, index) => {
                  return (
                    <div className="lightgreybox-bg-color px-4 py-2.5 m-4 rounded-3xl  flex justify-between">
                      <div>
                        <div className="text-lg font-bold">
                          {get(item, "title")}
                        </div>
                        <div>{get(item, "content") || "-"}</div>
                      </div>
                      <div className="flex justify-center items-center">
                        {" "}
                        <span
                          className="cursor-pointer underline"
                          style={{ color: mainColor }}
                          onClick={() => {
                            setVisible(true);
                            setSelectedType(item);
                          }}
                        >
                          {t("clickHere")}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </ScrollLoadWrapper>
            </div>
            <DocumentModal
              visible={visible}
              data={selectedType}
              onClose={() => {
                setVisible(false);
                setSelectedType({});
              }}
            />
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideosListingPage);
